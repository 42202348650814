import React, { useRef, useEffect } from "react";
import { Seo } from "../components/Seo";
import * as prismicH from '@prismicio/helpers'
import { useStaticQuery, graphql } from "gatsby";
import clsx from "clsx";
import '../styles/projects.css'
import ContactForm from "../components/ContactForm";
import Carousel from "../components/Projects/Carousel";

const SliceRenderer = ({slice, name}) => {
  switch (slice.slice_type) {
    case 'content_-_left_image':
      return <BlockRenderer imagePosition="left" slice={slice} />
    case 'content_-_right_image':
      return <BlockRenderer imagePosition="right" slice={slice} />
    case 'stats_section':
      return <StatsRenderer slice={slice} />
    case 'sourcing_wizardry_section':
      return <SourcingWizardry />
    case 'contact_form':
      return <ContactSection name={name} />
    default:
      return null;
  }
}

const BlockRenderer = ({slice, imagePosition}) => {
  const textRef = useRef();
  const imageRef = useRef();
  const { primary } = slice;

  const resizeSection = () => {
    if(textRef.current.querySelector('div') || false) {
      let mheight = textRef.current.querySelector('div').offsetHeight + 280;
      imageRef.current.style.maxHeight = `${mheight}px`;
    }
  }

  useEffect(() => {
    window.addEventListener('resize', resizeSection);
    resizeSection();
  }, []);

  return (
    <section>
      <div className="grid lg:grid-cols-2">
        <div className={clsx('flex', {
          'order-1': imagePosition === 'left',
          'order-2': imagePosition === 'right'
        })}
          ref={imageRef}>
          <img src={primary.image.url}
            alt={primary.image.alt}
            className="object-cover min-w-[100%]" />
        </div>
        <div className={clsx('py-24 px-16 xl:px-32', {
          'order-2': imagePosition === 'left',
          'order-1': imagePosition === 'right'
        })}
          ref={textRef}>
          <div className="dynamicProjectContent" 
            dangerouslySetInnerHTML={{ __html: prismicH.asHTML(primary.content) }}></div>
        </div>
      </div>
    </section>
  )
} 

const StatsRenderer = ({slice}) => {
  const columnCount = slice.items.length;
  return (
    <section className="bg-[#f8f8f8] py-24">
      <div className="xl:container grid-cols-1 lg:grid-cols-[var(--grid-cols-custom)] mx-auto px-6 grid text-center gap-x-12 gap-y-12"
        style={{'--grid-cols-custom': `repeat(${columnCount}, minmax(0, 1fr))`}}>
        {slice.items.map((i,k) => (
          <div key={k}>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              {i.before && <span>{i.before}</span>}
              {i.text && <span>{i.text}</span>}
              {i.value && <span>{i.value}</span>}
              {i.after && <span>{i.after}</span>}
            </p>
            {i.name && <p className="font-black text-xl">{i.name}</p>}
          </div>
        ))}
      </div>
    </section>
  )
} 

const SourcingWizardry = () => {
  return (
    <section className="bg-[#f8f8f8] py-32">
      <div className="xl:container mx-auto px-6 text-center">
        <p className="font-black text-5xl lg:text-7xl">
        Sourci puts product sourcing wizardry at the heart of your team. Giving total control and support, so those uncertainties just disappear. <span className="text-sourci-pink">You got this.</span>
        </p>
      </div>
    </section>
  )
}

const ContactSection = ({name}) => {
  return (
    <section className="bg-[#f8f8f8] py-32">
      <div className="xl:container lg:grid lg:grid-cols-2 mx-auto px-6">
        <div className="text-black">
          <p className="text-4xl md:text-6xl font-black mb-8">The time<br /> is now.</p>
          <p className="text-black text-4xl mb-8 font-black">Let's make amazing<br /> happen today.</p>
          <p className="text-3xl mb-2">
            <a href="tel:+611300768724"
              className="hover:underline">1300 SOURCI</a>
          </p>
          <p className="text-3xl mb-2">
            <a href="mailto:hello@sourci.com.au"
              className="hover:underline">hello@sourci.com.au</a>
          </p>
        </div>
        <div className="mt-12 lg:mt-0">
          <ContactForm bg="light"
            location={`Case Study - ${name}`}  />
        </div>
      </div>
    </section>
  )
}

const ProjectTemplate = ({pageContext}) => {
  const prismicData = useStaticQuery(graphql`
    query {
      allPrismicMixedContentProject {
        edges {
          node {
            data {
              title
              description
              cover_image {
                url
              }
            }
            uid
          }
        }
      }
    }
  `);
  const projects = prismicData.allPrismicMixedContentProject.edges.map((i,k) => ({
    to: i.node.uid,
    name: i.node.data.title,
    description: i.node.data.description,
    src: i.node.data.cover_image.url
  }))
  return (
    <main>
      {pageContext.data.body.map((i,k) => (
        <SliceRenderer key={k}
          slice={i}
          name={pageContext.data.name} />
      ))}
      <Carousel projects={projects}
        project={pageContext.data.to}
        title="Check out our other Projects" />
    </main>
  )
}

export default ProjectTemplate;

export function Head({pageContext}) {
  return (
    <Seo title={`${pageContext.data.name} | Our Projects | Sourci`}
      description={pageContext.data.description}
      canonical={`/projects/${pageContext.data.to}`} />
  )
}